<template>
  <div class="body add-yard">
    <div class="form">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        label-width="102px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="车场名称：" prop="name">
          <el-input type="text" v-model.trim="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所在区域：" prop="city">
          <el-cascader
            :options="cityList"
            clearable
            v-model="addForm.city"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="场半径(米)：" prop="radius">
          <el-input-number
            type="text"
            :min="0"
            v-model.trim="addForm.radius"
            controls-position="right"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="经度：" prop="longitude">
          <el-input type="text" v-model.trim="addForm.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度：" prop="latitude">
          <el-input type="text" v-model.trim="addForm.latitude"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { checkLong, checkLat } from '@/common/utils/index'
import { queryAreaList } from '@/api/lib/api.js'
import {
  insertBusPark,
  updateBusPark,
  queryBusParkById
} from '@/api/lib/bus-api.js'
export default {
  props: {
    itemId: {
      type: [String, Number],
      default: () => { }
    }
  },

  data () {
    return {
      cityList: [],
      addForm: {
        name: null,
        city: [],
        radius: null,
        longitude: null,
        latitude: null,
      },
      rules: {
        name: [{ required: true, message: "请输入车场名称", trigger: "blur" }],
        city: [{ required: true, type: 'array', message: "请选择所在区域", trigger: "change" }],
        radius: [{ required: true, message: "请输入场半径", trigger: "blur" }],
        longitude: [{ required: true, message: "请输入经度", trigger: "blur" }, { validator: checkLong, trigger: 'blur' }],
        latitude: [{ required: true, message: "请输入纬度", trigger: "blur" }, { validator: checkLat, trigger: 'blur' }],
      },
    };
  },

  methods: {
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            name: this.addForm.name,
            city: this.addForm.city,
            radius: this.addForm.radius,
            longitude: this.addForm.longitude,
            latitude: this.addForm.latitude
          }
          if (!this.isEdit) {
            insertBusPark(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            updateBusPark(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },

  created () {
    this.getAreaList()
  },
  mounted () {
    if (this.itemId) {
      queryBusParkById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }

          delete this.addForm.createTime
          delete this.addForm.updateTime
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  }
};
</script>

<style lang="scss" scoped>
.add-yard {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin-bottom: 10px;
    }
  }
}
</style>
